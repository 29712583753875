.upload-page {
    margin-top: 4rem; 
}

.message {
    white-space: pre-wrap;
    line-height: 2;
}

.introduction-box {
	width: 90%;
	min-height: 300px;
	padding: 8px;
	display: flex;
	align-items: flex-start;
	align-content: flex-start;
	white-space: normal;
	font-size: 16px;
	resize: none;
	border-radius: 10px;
}

.introduction-:focus {
	border: 2px solid white;
}