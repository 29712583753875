
.home-page-bottom {
	padding-top: 1rem;
	padding-inline: 2.5rem;
	box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.08), 0px 8px 10px 1px rgba(0,0,0,0.08), 0px 3px 14px 2px rgba(0,0,0,0.08);
	background-color: white;
}

.home-page-journals {
	display: flex;
	flex-wrap: wrap;
    gap: 10px;
	margin-block: 2rem;
	margin-inline: 5rem;
}

.home-page-articles {
	display: flex;
	flex-direction: column;
	gap: 10px;
	margin-block: 2rem;
	margin-inline: 5rem;
}

.journal-section-heading {
	margin-block: 2rem;
	margin-inline: 5rem;
}

.journals-heading-empty {
	margin-bottom: 0px;
}

.journals-text-empty {
	margin-block: 0px;
}

@media screen and (max-width: 750px) {
	.home-page-bottom {
		padding-inline: 1.5rem;
	}

	.home-page-titles {
		margin-bottom: 2rem;
	}

}

/* CUSTOM SELECT */
/* Dropdown button styles */
.dropdown-btn {
	cursor: pointer;
	padding-bottom: 15px;
	border-bottom: 1px solid var(--light-gray);
  }
  
  /* Dropdown content styles */
  .dropdown-content {
	display: flex;
	flex-direction: column;
	background-color: #ffffff;
	z-index: 1;
	min-height: max-content;
	width: 100%;
  }
  
  /* Dropdown link styles */
  .dropdown-content a {
	display: block;
	padding: 10px 15px;
	text-decoration: none;
	color: #333;
  }

.MuiSvgIcon-root {
	fill: var(--dark-blue) !important;
	height: 18px !important;
}

.MuiListSubheader-root {
	font-weight: 700 !important;
	color: var(--dark-blue) !important;
}