
.search-bar-input-row {
	gap: 15px;
	border-bottom: 1px solid var(--dust);
}

.search-bar-input {
	border: none;
	outline: none;
	background-color: transparent;
}

.home-page-search-input {
	width: 100%;
}

.MuiList-root {
	display: flex;
	flex-direction: column;
	margin: 20px !important;
	max-width: 300px !important;
	min-width: 300px !important;
	right: 20px;
}

.MuiMenu-list {
	padding: 5px 30px !important;
}

.MuiSelect-nativeInput {
	opacity: 1 !important;
	border: 0;
	text-align: right;
	padding-right: 40px;
	height: 40px;
}

/* label text */
.MuiTypography-root {
	font-size: 13px !important;
	font-family: 'Raleway' !important;
}

/* checkbox */
.MuiSelect-icon {
	height: 13px !important;
}

 /* filter svg */
.navbar path {
	fill: var(--cloud) !important;
}

.MuiFormControl-root {
	margin: 0 !important;
}

.MuiInput-input  .MuiListItemText-root {
	display: none !important;
}

.MuiInput-input .MuiCheckbox-root {
	border: none !important;
}

.MuiInput-root::before, .MuiSelect-root::before {
	border-bottom: none !important;
	display: none !important;
}

.MuiInput-input:focus {
	background-color: unset !important;
}


/* Checkbox line */
.MuiMenuItem-root {
	max-height: 25px !important;
}

.Mui-selected {
	background-color: transparent !important;
}

.Mui-selected:hover {
	background-color: transparent !important;
}

.Mui-selected:active {
	background-color: transparent !important;
}

.Mui-selected:focus {
	background-color: transparent !important;
}

.MuiButtonBase-root {
	background-color: transparent !important;
}


/* Checkbox Group Title */
.MuiListSubheader-root {
	color: black !important;
	font-size: 14px !important;
	text-transform: uppercase !important;
	line-height: 20px !important;
	margin: 20px 0 10px 0;
	font-family: 'Raleway' !important;
	font-weight: 600 !important;
}

/* Button */
button {
	justify-content: flex-start !important;
	color: var(--medium-gray) !important;
	text-decoration: underline !important;
	font-size: 11px !important;
	padding: 10px 0 10px 15px !important;
}

/* Filter Icon */
.MuiSelect-nativeInput {
	background-color: transparent;
	display: none;
}



/* Search Icon */



.MuiSelect-icon path {
	fill: var(--medium-blue) !important;
}