.article-page {
    margin-top: 2rem;
	padding: 20px 90px 30px 60px;
    box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.08), 0px 8px 10px 1px rgba(0,0,0,0.08), 0px 3px 14px 2px rgba(0,0,0,0.08);
	background-color: white;
}

.article-page h1 {
	max-width: 75%;
}

.article-bottom {
	display: flex;
	padding-top: 2rem;
}

.abstract {
	margin-bottom: 3rem;
}

.article-summary-block {
	width: 70%;
	padding-right: 3rem;
}

.article-info-block {
	width: 30%;
	border-left: 1px solid var(--medium-gray);
	padding-left: 3rem;
}

.article-info-section {
	margin-top: 2.5rem;
}

.citation-text-line {
	display: flex;
	gap: 16px;
	align-items: center;
	margin-block: 10px;
}

.citation-text {
	margin-block: 0px;
}

.reactMarkdown, li {
	font-family: 'Raleway';
	font-size: 15px;
	color: var(--cloud);
	letter-spacing: 0px;
    line-height: 20px;
	margin-bottom: 5px;
    margin-top: 1px;
	width: 80%;
    padding: 0;
}

.language-buttons {
    display: flex;
    /* Align items in a row */
    gap: 10px;
    /* Space between buttons */
    margin-top: 0px;
    /* Space above the buttons */
    margin-bottom: 0px;
    align-items: center;
    /* Vertically align items in the container */
}
.language-background-button {
    display: inline-block;
    padding: 0px 10px;
    border: 1px solid var(--medium-gray);
    background-color: white;
    color: var(--dark-blue);
    text-decoration: none;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    border-radius: 2px;
    transition: background-color 0.3s, color 0.3s;
    text-decoration: none;
    /* Remove default button styling */
    min-width: 90px;
    /* Optional: Ensures uniform width for all buttons */
    height: 35px;
    /* Set height for consistency */
        /* Remove any margin */
}

.language-background-button.button {
    cursor: pointer;
}

.language-background-button:hover {
    background-color: var(--light-gray);
    color: var(--medium-blue);
}

.language-background-button:active {
    background-color: var(--medium-gray);
    color: var(--dark-blue);
}


.article-page .react-icon {
	margin: 0 5px;
	height: 15px;
	fill: var(--light-blue)
}

.article-page .react-icon path {
	color: var(--light-blue) !important;
}


ul {
	margin: 8px 0 0 0;
}

p strong {
	margin-top: 30px;
	font-weight: 700;
	display: block;
	font-size: 14px;
	color: var(--medium-gray);
	text-transform: uppercase;
}

strong {
	font-weight: 600;
	color: var(--medium-blue);
}

.citation-text-line {
	margin: 0;
}

.tag-list {
	margin-bottom: 15px;
}

.tag-text {
	color: var(--medium-gray);
	padding: 5px 10px;
	background-color: var(--light-blue);
	max-width:fit-content;
	border-radius: 5px;
	font-size: 13px;
	margin: 6px 0;
	line-height: 18px;
}

#sparkle path {
	fill: var(--light-blue)
}

.summary-disclaimer {
	margin-top: 20px;
	font-size: 13px;
	text-transform: none;
	letter-spacing: normal;
}

.summary-disclaimer a {
	color: var(--dark-blue);
}

@media screen and (max-width: 800px) {

	.article {
		max-width: 100%;
	}

	.article-page h1 {
		max-width: 90%;
	}

	.article-bottom {
		flex-direction: column;
		margin-top: 0.25rem;
	}

	.article-summary-block {
		width: 100%;
		padding: 0 0 1rem 0;
	}

	.article-info-block {
		width: 100%;
		border: none;
		padding: 1rem 0 0 0;
		border-top:  1px solid var(--medium-gray);
		display: flex;
		flex-wrap: wrap;
		gap: 1rem;
	}

	.article-page {
        padding: 10px 30px 20px 30px;
    }

	.article-info-section {
		margin: 0;
	}

	ul {
		margin: 4px 0 0 0;
	}

	.article-page .button {
		margin-block: 0;
		font-size: 11px;
	}
}

@media screen and (max-width: 600px) {
    .aricle-page {
        padding: 10px 30px 20px 30px;
    }
}