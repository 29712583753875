.MuiMenuItem-root {
	font-size: 20px;
	font-weight: 450;
}

.hamburgerIcon {
	font-size: 25px;
	color: white !important;
	transition: none;
	outline: 0;
}

.hamburgerIcon:hover,
.hamburgerIcon:focus {
	color: var(--white-text-hover) !important;
}


#bars {
	display: flex;
	flex-direction: column;
	gap: 6px;
}

.hamburger-bars:hover {
	cursor: pointer;
}

.bar1,
.bar2,
.bar3 {
	transition: 0.3s ease-in-out;
}

.closed .bar1 {
	transform: translate(0px, 7px) rotate(-45deg);
}

.closed .bar2 {
	display: none;
}

.closed .bar3 {
	transform: rotate(45deg);

}

.bar {
	height: 1px;
	width: 22px;
	background-color: black;
	margin: 0px;
}

@media (max-width: 1000px) {
	.faIcon {
		font-size: 18px !important;
	}

	.hamburgerMenu .MuiMenuItem-root {
		padding: 20px 0 20px 0 !important;
		min-height: unset;
		font-family: 'Promxima Nova', sans-serif !important;
		color: var(--graphite) !important;
		font-size: 12px !important;
 		text-transform: uppercase !important;
  		letter-spacing: 1.5px !important;
		color: var(--cloud) !important;
		font-weight: 300 !important;
		border-bottom: 0.75px solid var(--light-gray);
	}

	.hamburgerMenu .MuiList-root {
		padding-block: 2px !important;
	}

	.hamburgerIcon {
		font-size: 20px;
	}

	.hamburger-container {
		float: right;
		max-width: 60%;
	}

	.navbar .MuiMenu-paper {
		max-width: min-content !important;
		max-width: 200px !important;
	}
}

@media screen and (min-width: 1000px) {
    .hamburger-container {
        display: none;
    }
}
