.navbar {
	justify-content: space-between;
	position:absolute;
	top: 0;
	left: 0;
	right: 0;
	padding: 20px 50px 15px 50px;
	border-bottom: 0.5px solid rgba(128, 128, 128, 0.6);
	margin-bottom: 2rem;
	display: block;
	background-color: white;
	display: flex;
	gap: 40px;
}

.navbar h3 {
	font-size: 19px;
	line-height: 22px;
}

.navbar .search-bar-input-row {
	border-bottom: none;
}

.navbar .subtle-text {
	margin-block: 0px;
}

.navbar-home .search-link {
	display: none;
}

/* 
Using Image instead
.title-text {
	font-family: "Lora", serif;
	font-size: 25px;
	line-height: 1.1;
	left: 0;
	font-weight: 400;
	margin: 0 15px 0 0;
	color: var(--dark-blue) !important;
} 

.title-text Link:hover {
	text-decoration: underline;
	cursor: pointer;
}
*/

.title-text img {
	height: 50px;
	width: auto;
}

.title-text img:hover {
	opacity: .85;
	cursor: pointer;
}

.title-text-mobile img {
	height: 45px;
	width: auto;
}

.navbar h5:hover {
	text-decoration: none !important;
}

.navbar h5 {
	display: none;
	margin-top: 5px;
	font-size: 10px;
	line-height: 18px;
}

.left-side {
	display: flex;
	align-items: center;
	gap: 30px;
	flex-grow: 2;
}

.right-side {
	gap: 20px;
}

.home-link,
.search-link {
	gap: 10px;
}

/* .home-link,
.search-link {
	gap: 10px;
}

.search-link {
	width: 40%;
} */

.home-text {
	margin-block: 0px;
}

.nav-search-div {
	display: flex;
	flex-direction: column;
	z-index: 9;
	/* border-bottom: 1px solid var(--graphite); */
	padding: 7px 15px;
	max-width: min-content;
	background-color: var(--light-blue);
	border-radius: 10px;
}

.nav-search-div .search-bar-input-row {
	max-height: 30px;
	display: flex;
	justify-content: flex-start;
}

.link {
	min-width: max-content;
}

.navbar-mobile {
	display: none;
}

.title-text-mobile {
	font-family: "Lora", serif;
	font-size: 17px;
	line-height: 1.1;
	left: 0;
	font-weight: 400;
}

@media screen and (max-width: 1000px) {
	.navbar {
		display: flex;
		justify-content: space-between;
		padding: 20px 0;
		border-bottom: 1px solid var(--silver);
		gap: 0;
	}

	.navbar-desktop {
		display: none;
	}

	.navbar-mobile {
		display: block;
	}
		

	.right-side .link {
		display: none !important;
	}

	.title-text {
		position: absolute;
		top: 0;
		width: 100%;
		text-align: center;
		margin-bottom: 10px;
		font-size: 18px;
		border-bottom: 1px solid var(--light-gray);
	}

	.navbar h5 {
		font-size: 10px;
	}

	.search-bar-input-row {
		min-width: 100%;
	}

	/* .search-link {
		width: 90%;
	} */

	.left-side {
		gap: 0;
		width: 100%;
		display: flex;
		justify-content: space-between;
		margin-inline: 3rem;
	}

	.navbar-mobile-left {
		display: flex;
		gap: 20px;
		align-items: center;
		width: 100%;
	}

	.navbar-home .navbar-mobile-left {
		display: flex;
		flex-direction: row-reverse;
		gap: 20px;
		align-items: center;
		justify-content: space-between;
		width: 100%;
	}

}

@media screen and (max-width: 600px) {
	.title-text-mobile {
		text-align: left;
		font-size: 16px;
	}

	.navbar {
		padding: 15px 20px 15px 5px;
	}

	.navbar .flex {
		justify-content: flex-end
	}

	.left-side {
		margin-inline: 1rem;
		flex-direction: column;
		align-items: flex-start;
		gap: 15px;
	}

	/* .search-link {
		width: 90%;
		margin-top: 5px;
		max-height: 25px;
		justify-content: center;
	} */

	.navbar path {
		fill: var(--cloud) !important;
		max-height: 10px;
	}

	.navbar-mobile-left {
		flex-direction: row-reverse;
		justify-content: space-between;
	}
}