.journal-page {
    margin-top: 1rem;
    padding: 20px 90px 30px 60px;
    box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.08), 0px 8px 10px 1px rgba(0,0,0,0.08), 0px 3px 14px 2px rgba(0,0,0,0.08);
    background-color: white;
}

.journal-page h1 {
    padding: 0 0 20px 0;
    border-bottom: 1px solid var(--light-gray);
}

.journal-content-container {
    width: 100%;
    margin-top: 2.5rem;
    display: flex;
    justify-content: space-between;
}

.volumes-list {
    min-width: 30%;
    display: flex;
    flex-direction: column;
    margin-right: 20px;
}

.recent-articles {
    width: 60%;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.recent-article {
    border-bottom: 1px solid var(--light-gray);
    padding: 0 0 15px 0;
}

.issue-loading-bars {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 10px;
}

.recent-articles-loading-bars {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 10px;
}

.journal-page .MuiPaper-root {
    min-width: max-content !important;
}

.MuiAccordionSummary-root {
    border-bottom: 1px solid var(--light-gray) !important;
}

.issue {
    font-size: 15px;
    margin: 5px 0;
}

@media screen and (max-width: 1000px) {
    .journal-page {
        padding: 20px 50px 30px 50px;
    }
}

@media screen and (max-width: 800px) {
    .journal-content-container  {
        margin-top: 1rem;
        flex-direction: column;
        gap: 40px;
    }

    .volumes-list {
        width: 100%;
    }

    .journal-page {
        padding: 10px 60px 20px 60px;
    }

    .recent-articles {
        width: 100%;
        gap: 8px;
    }
}

@media screen and (max-width: 600px) {
    .journal-page {
        padding: 10px 30px 20px 30px;
    }
}