@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400..700;1,400..700&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

:root {
  --graphite:  rgb(48, 48, 48);
  --cloud: rgb(102, 102, 102);
  --dust: rgb(234, 234, 234);
  --medium-gray: rgb(129, 137, 145);
  --light-gray: rgb(227, 227, 227);
  --silver: rgb(247, 247, 247);
  --dark-blue: #0d2a4a;
  --light-blue: #f4f5f6d2;
  --background-blue: #e7ebf0d2;
  --medium-blue: #474f59;
}

#root {
  margin-inline: 8rem;
  margin-top: 9rem;
  
}

html {
  /* background: linear-gradient(14deg, rgb(233, 241, 249) 0%, rgb(247, 250, 254) 41%, rgba(255,255,255,1) 100%); */
  background: rgba(233, 237, 240, 0.2)
}

.flex {
  display: flex;
  align-items: center;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

a {
  text-decoration: none;
  color: var(--dark-blue);
}

a:hover {
  text-decoration: underline;
  cursor: pointer;
}

.react-icon {
	height: 12px;
}

.react-icon path {
	fill: var(--medium-blue) !important;
}

.react-icon:hover {
  cursor: pointer;
}

h1 {
  font-family: "Lora", serif;
  font-size: 33px;
  font-weight: 400;
  font-style: normal;
  color: var(--dark-blue);
  line-height: 45px;
  margin-bottom: 13px;
}

.h1-big {
  font-size: 50px;
  margin-bottom: 20px;
  font-weight: 600;
  letter-spacing: -0.5px;
  color:rgb(25, 75, 124);
  text-transform: capitalize;
}

h2 {
  font-family: 'Raleway', sans-serif;
  font-weight: 600;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 2px;
  line-height: 28px;
  margin-block: 0.5rem;
  color: var(--cloud);
}

h3 {
  font-family: "Lora", serif;
  font-weight: 400;
  font-style: normal;
  font-size: 20px;
  line-height: 28px;
  color: var(--graphite);
  text-transform: none;
  letter-spacing: 0.5px;
  margin: 5px 0 10px 0;
}

h4 {
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  font-size: 15px;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: var(--graphite);
  margin: 5px 0 12px 0;
}

h5 {
  font-family: 'Raleway', sans-serif;
  font-weight: 600;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 2px;
  line-height: 22px;
  margin: 7px 0;
  color: var(--medium-blue);
}

h6 {
  font-family: 'Raleway', sans-serif;
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin: 5px 0;
  color: var(--medium-gray);
}

.subtle-text {
  font-family: 'Raleway', sans-serif;
  font-weight: 500;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin: 20px 0;
  color: var(--medium-blue) !important;
}


p {
  margin-block: 1px;
  font-size: 16px;
  font-family: 'Raleway', sans-serif;
  color: var(--medium-blue);
  letter-spacing: 0.5px;
  line-height: 26px;
  font-weight: 500;
}

ul {
  margin: 5px 0;
}

li {
  margin-bottom: 0.75rem;
  font-weight: 500;
  color: var(--medium-blue);
  font-size: 16px;
  line-height: 26px;
}

.white-text {
  color: white;
}

.button {
  font-family: 'Raleway', sans-serif;
  font-weight: 600;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-block: 1.5rem;
  padding: 15px;
  display: flex;
  width: fit-content;
  border: 2px solid var(--dark-blue);
  transition: ease-in-out 0.3s;
  border-radius: 5px;
}

.black-background-button {
  color: white;
  background-color: var(--dark-blue);
}

.black-background-button:hover {
  background-color: var(--dark-blue);
  opacity: .9;
  text-decoration: none;
}

.disabled-button {
  background-color: var(--cloud);
  border: 2px solid var(--medium-gray);
  cursor: not-allowed;
}

.disabled-button:hover {
  background-color: var(--cloud);
  border: 2px solid var(--medium-gray);
  cursor: not-allowed;
}

.white-background-button {
  background-color: white;
  color: var(--dark-blue);
}

.white-background-button:hover {
  background-color: var(--light-gray);
  text-decoration: none;
}

.tag {
  padding: 2px 12px;
  background-color: var(--silver);
  text-transform: uppercase;
  color: var(--cloud);
  width: fit-content;
  font-size: 10px;
  border-radius: 10px;
  margin: 0 10px 5px 0;
}


.loading-div {
  position: relative;
}

@media screen and (max-width: 1100px) {
  #root {
    margin-inline: 4rem;
  }
}

@media screen and (max-width: 950px) {
  #root {
    margin-inline: 3rem;
  }

  .h1-big {
    font-size: 53px;
    margin-bottom: 23px;
  }

  h1 {
    font-size: 35px;
    line-height: 42px;
  }

  h2 {
    font-size: 15px;
    margin-bottom: 10px;
  }

  h5 {
    font-size: 12px;
  }

  p {
    font-size: 14px;
  }
}

@media screen and (max-width: 750px) {
  #root {
    margin-inline: 2rem;
    margin-top: 7rem;
  }

  .h1-big {
    font-size: 45px;
    margin-bottom: 25px;
  }

  h1 {
    font-size: 30px;
    line-height: 35px;
  }

  h2 {
    font-size: 13px;
    line-height: 20px;
    margin-bottom: 3px;
  }

  h3 {
    font-size: 18px;
    line-height: 25px;
  }

  h5 {
    font-size: 15px;
    line-height: 24px;
  }

  p {
    font-size: 13px;
  }
}

@media screen and (max-width: 600px) {
  #root {
    margin-inline: 1rem;
    margin-top: 6rem;
  }

  h1 {
    font-size: 24px;
    line-height: 30px;
    max-width: 100%;
  }

  h5 {
    margin-top: 8px;
    line-height: 20px;
    font-size: 13px;
    font-weight: 500;
  }

  .subtle-text {
    font-size: 12px;
  }

  p, li {
    font-size: 15px;
    line-height: 20px;
    width: 90%;
  }

  ul {
    padding-left: 15px;
  }

}