.issue-page {
    margin-top: 2rem;
	padding: 20px 90px 30px 60px;
	box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.08), 0px 8px 10px 1px rgba(0,0,0,0.08), 0px 3px 14px 2px rgba(0,0,0,0.08);
	background-color: white;
}

.issue-page-title {
	padding-bottom: 1rem;
	border-bottom: 1px solid rgb(195, 195, 195);
}

.issue-articles {
	margin-top: 2rem;
	justify-content: space-around;
}


@media screen and (max-width: 800px) {
	.issue-articles {
		margin-top: 1.25rem;
	}

	.issue-page {
		padding: 20px 60px 30px 60px;
	}
}

@media screen and (max-width: 600px) {
    .issue-page {
        padding: 10px 30px 20px 30px;
    }
}