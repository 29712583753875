
.nav-search-container {
	width: 30%;
	background-color: white;
	border: 1px solid var(--medium-gray);
	position: absolute; 
	margin-top: 50px;
	height: fit-content;
	max-height: 75vh;
	overflow: scroll;
	box-shadow: 10px 10px 30px var(--light-gray);
}

.circular-progress {
	width: max-content;
	margin-top: 100px;
	margin-inline: auto;
	min-height: 500px;
}

.navbar .journal-link-button {
	width: 95%;
}

.search-home-page {
	position: relative;
	border: none;
	width: auto;
	margin-top: 1rem;
	box-shadow: none;
}

.journal-section-heading {
	margin-block: 2rem;
	margin-inline: 5rem;
}

.navbar .journal-section-heading {
	margin-inline: 2rem;
}

.journals-heading-empty {
	margin-bottom: 0px;
}

.journals-text-empty {
	margin-block: 0px;
}

.home-page-journals {
	display: flex;
	flex-wrap: wrap;
    gap: 10px;
	margin-block: 2rem;
	margin-inline: 5rem;
	margin-inline: 3rem;
}

.navbar .home-page-articles {
	margin-inline: 2rem;
}

.home-page-articles {
	display: flex;
	flex-direction: column;
	gap: 10px;
	margin-block: 2rem;
	margin-inline: 5rem;
}

.article {
	flex: 1 1 45%; /* Adjust the percentage as needed */
	margin: 5px;
	padding: 10px 0 10px 0;
	max-width: 80%;
	border-bottom: 1px solid rgba(192, 192, 192, 0.43);
}


.journal-link-button {
	display: flex;
	width: 31%;
	max-width: 320px;
	height: 125px;
	position: relative;
	background-color: var(--light-blue);
	border: 1.5px solid white;
	border-radius: 6px;
}

.journal-link-button:hover {
	outline: 2px solid var(--light-gray);
	outline-offset: -8px
}

.journal-link-text {
	font-family: "Raleway", sans-serif;
	font-weight: 500;
	font-size: 16px;
	position: absolute;
    bottom: 0;
    left: 0;
	margin: 0px;
    padding: 0 0 20px 25px;
	max-width: 170px;
	letter-spacing: 0.5px;
	line-height: 22px;
	color: var(--medium-blue);
}

.journal-link-button:hover {
	outline: 2px solid var(--light-gray);
	outline-offset: -8px
}


@media screen and (max-width: 1280px) and (min-width: 990px) {
	.journal-link-button {
		width: 46%;
		max-width: none;
	}

	.home-page-journals, .home-page-articles {
		margin-inline: 3rem;
	}
}

@media screen and (max-width: 990px) and (min-width: 836px) {
	.journal-link-button {
		width: 47%;
		height: 115px;
		max-width: none;
	}

	.journal-link-text {
		font-size: 18px;
	}

	.home-page-journals, .home-page-articles {
		margin-inline: 3rem;
	}
  }

@media screen and (max-width: 835px) {
	.journal-link-button {
		width: 100%;
		max-width: none;
		height: 85px;
	}


	.journal-section-heading {
		margin-inline: 0.5rem;
		margin-block: 1rem;
	}

	.article {
		width: 98%;
	}

	.journal-link-text {
		max-width: 80%;
		font-size: 15px;
	}

	.home-page-journals, .home-page-articles {
		margin-inline: 0.5rem;
	}

	.navbar .nav-search-container {
		width: 90%;
		margin-top: 35rem;
		margin-left: -10px;
	}
}