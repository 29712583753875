.contact-page,
.about-page {
    margin-top: 4rem;   
	display: flex;
	flex-direction: column;
}

.other-page-header {
	margin-bottom: 2rem;
}

.about-page strong,
.contact-page strong {
	margin-top: 0px;
	display: inline;
	font-size: unset;
	text-transform: none;
	color: unset;
	font-weight: 600;
	color: var(--medium-blue);
}

.about-page li {
	margin-bottom: 0.75rem;
	font-weight: 500;
	color: var(--medium-blue);
	font-size: 16px;
	line-height: 26px;
}

.about-page ul {
	margin: 20px 0 0 0;
}

.about-page img {
	padding: 5px;
	border-radius: 5px;
	border: 2px solid var(--light-gray);
	margin: 10px 0 20px 0;
}

.faq-page .MuiButtonBase-root, .about-page .MuiButtonBase-root {
	background-color: transparent  !important;
	border-bottom: 1px solid var(--medium-blue) !important;
	height: auto !important;
}

.faq-page .MuiAccordion-root,  .about-page .MuiAccordion-root {
	background-color: transparent !important;
	display: flex !important;
	flex-direction: column !important;
	gap: 30px !important;
}

.faq-content {
	margin-top: 40px;
	display: flex;
}

.about-page a,
.faq-page a {
	text-decoration: none;
	font-weight: 600;
	border-bottom: 2px solid rgb(206, 210, 216);
	color:rgb(129, 135, 144);
	transition: ease-in 0.3s;
  }
  
.about-page a:hover,
.faq-page a:hover {
	text-decoration: none;
	border-bottom: 2px solid rgb(144, 153, 165);
	cursor: pointer;
  }
  
.about-page u,
.faq-page u {
	text-decoration-color: rgb(200, 200, 208);
  }

@media screen and (max-width: 800px) { 
	.about-page, .contact-page, .faq-page {
		padding: 10px 20px 20px 20px;
	}
}
